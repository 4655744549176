import {
  isPatient,
  isTherapist,
  isAdmin,
  isSuperAdmin,
  isDPO,
  isSiteManager,
  roles,
  isDataScientist,
  isPublisher,
  isAccountExecutive,
  isAdminManager,
  isDataExportManager,
  isOptionalFeatureManager,
  competences,
} from "@/services/auth.js";
import {
  deserializeConsent,
  isLatestPossiblePrivacyPolicySigned,
  MaxConsents,
} from "@/utils/signedDocuments.js";
import { resetState } from "@/store/utils";
import { clearStores } from "@/store";
import {
  areCookiesAccepted,
  removeAccessTokenCookie,
  setAccessTokenCookie,
} from "@/utils/cookies.js";
import {
  removeLocalStorageRefreshToken,
  removeLocalStorageStayLoggedIn,
  getLocalStorageStayLoggedIn,
} from "@/store/localStorage.js";

const initialState = () => {
  return {
    /**
     * JWT token
     */
    token: null,

    /**
     * Whether the cookies are accepted. It is used to broadcast the value
     * across components.
     */
    cookiesAccepted: false,

    /**
     * Whether to hide the cookie bar. It is used to broadcast the value
     * across components
     */
    hideCookieBar: false,

    /**
     * The roles the user has
     *
     * The API sends us a list, so let's save it as a list too, regardless of
     * the fact that for now in our code, you can only have one role
     */
    roles: [],

    /**
     * The competences the admin user has
     */
    competences: [],

    /**
     * The user profile of the logged-in user
     */
    user: null,

    /**
     * Which of the consents were accepted by the user
     */
    consents: Object.assign({}, Array(MaxConsents).fill(false)),
  };
};

export default {
  namespaced: true,

  state: initialState,
  mutations: {
    /**
     * Reset the store state
     * @param {object} state Module state
     */
    clearStore(state) {
      resetState(initialState, state);
    },

    /**
     * Reset all states including this one
     * @param {object} state Module state
     */
    clearAll(state) {
      resetState(initialState, state);

      clearStores();
      removeAccessTokenCookie();
      removeLocalStorageRefreshToken();
      removeLocalStorageStayLoggedIn();
    },

    /**
     * Set the JWT access token
     * @param {object} state Vuex state
     * @param {string} token token
     */
    setAccessToken(state, token) {
      state.token = token;
      if (areCookiesAccepted()) {
        // Store the cookie as a session cookie
        // if we're not supposed to stay logged in
        const isSessionCookie = !getLocalStorageStayLoggedIn();
        setAccessTokenCookie(token, isSessionCookie);
      }
    },

    /**
     * Set whether the user accepted cookies
     * This is set by the cookies bar
     * @param {object} state Vuex state
     * @param {boolean} cookiesAccepted Whether the cookies were accepted
     */
    setCookiesAccepted(state, cookiesAccepted) {
      state.cookiesAccepted = !!cookiesAccepted;
    },

    /**
     * Hides the cookie bar
     * @param {object} state Vuex state
     * @param {boolean} hideCookieBar Whether the cookie bar should be hidden
     */
    setHideCookieBar(state, hideCookieBar) {
      state.hideCookieBar = !!hideCookieBar;
    },

    /**
     * Set the roles of the user
     * Do some validation of the role strings sent by the API
     * @param {object} state Vuex state
     * @param {array} roles List of roles
     */
    setRoles(state, roles) {
      state.roles = roles;
    },

    /**
     * Set the competences of the user
     * @param {object} state Vuex state
     * @param {array} competences List of roles
     */
    setCompetences(state, competences) {
      state.competences = competences;
    },

    /**
     * Set the user object
     * @param {object} user The user object
     */
    setUserObject(state, user) {
      state.user = user;
    },

    /**
     * Expecting a object like in the state above
     */
    setConsents(state, consents) {
      state.consents = consents;
    },

    updatePrivacyPolicy(state, newDocument) {
      if (!state.user.signedDocuments) {
        state.user.signedDocuments = [];
      }
      state.user.signedDocuments.push(newDocument);
    },

    setConsentFormSignatureRequired(state, newState) {
      state.user.isConsentFormSignatureRequired = newState;
    },
  },

  actions: {
    setAccessToken({ commit }, token) {
      // Prevents storing undefined or null as a string
      if (!token) {
        token = "";
      }

      commit("setAccessToken", token);
    },

    setCookiesAccepted({ commit }, cookiesAccepted) {
      commit("setCookiesAccepted", !!cookiesAccepted);
    },

    setHideCookieBar({ commit }, hideCookieBar) {
      commit("setHideCookieBar", !!hideCookieBar);
    },

    setRoles({ commit }, newState) {
      if (!newState) {
        throw `invalid roles given: ${newState}`;
      }

      for (const role of newState) {
        if (roles.indexOf(role) === -1) {
          // something has gone wrong: the API sent us a role we don't understand
          throw `invalid Role found: ${role}`;
        }
      }
      commit("setRoles", newState);
    },

    setCompetences({ commit }, newState) {
      if (!newState) {
        return;
      }

      for (const competence of newState) {
        if (competences.indexOf(competence) === -1) {
          // something has gone wrong: the API sent us a competence we don't
          // understand;
          throw `invalid Competence found: ${competence}`;
        }
      }
      commit("setCompetences", newState);
    },

    /**
     * Deletes all user related data, including authentication tokens
     */
    clearAll({ commit }) {
      commit("clearAll");
    },

    setUserObject({ commit, dispatch }, newState) {
      commit("setUserObject", newState);
      dispatch("Language/setLanguage", newState.language, { root: true });
    },
    updatePrivacyPolicy({ commit }, newDocument) {
      commit("updatePrivacyPolicy", newDocument);
    },
    setConsentFormSignatureRequired({ commit }, newState) {
      commit("setConsentFormSignatureRequired", newState);
    },

    /**
     * I'm not happy with this solution. We already have all the data
     * in state.userProfile, and we repeatedly do something with the
     * returned data from the API here. Should somehow be re-written.
     * Same applies for the roles: we save them outside of the user object,
     * but in the user object too. Feels very WET
     */
    saveConsent({ commit }, userProfile) {
      if (!isPatient(userProfile.roles)) {
        // only for patient
        return;
      }

      const consents = deserializeConsent(userProfile.signedDocuments);
      commit("setConsents", consents);
    },

    setConsents({ commit }, consents) {
      commit("setConsents", consents);
    },
    clearStore({ commit }) {
      commit("clearStore");
    },
  },
  getters: {
    /**
     * Whether the user has an access token
     * @param {object} state Module state
     */
    hasAccessToken(state) {
      return state.token !== null;
    },

    /**
     * Whether the user needs to sign the privacy policy
     * @param state
     */
    isPrivacyPolicySignatureRequired(state) {
      // Don't check the privacy policy when the current user is not defined
      // or when the backend doesn't think this is needed.
      // (e.g. admins, already up to date, ...)
      if (!state.user || !state.user.isPrivacyPolicySignatureRequired) {
        return false;
      }

      // Otherwise check the current user document status
      return !isLatestPossiblePrivacyPolicySigned(
        state.user?.signedDocuments || []
      );
    },

    /**
     * Whether the user needs to sign the consent form
     * @param state
     */
    isConsentFormSignatureRequired(state) {
      if (state.user != null) {
        return state.user.isConsentFormSignatureRequired;
      }
      return false;
    },

    /**
     * Whether the user is a MindMotion user (Therapist, Patient or SiteManager)
     * @param {object} state Module state
     */
    isMindMotionUser(state) {
      return (
        isPatient(state.roles) ||
        isTherapist(state.roles) ||
        isSiteManager(state.roles)
      );
    },

    isPatient(state) {
      return isPatient(state.roles);
    },

    isTherapist(state) {
      return isTherapist(state.roles);
    },

    isAdmin(state) {
      return isAdmin(state.roles);
    },

    isSuperAdmin(state) {
      return isSuperAdmin(state.roles);
    },

    isDPO(state) {
      return isDPO(state.roles);
    },

    isSiteManager(state) {
      return isSiteManager(state.roles);
    },

    isSuperTherapist(state) {
      if (!state.user) {
        return false;
      }
      return state.user.isSuperTherapist && isTherapist(state.roles);
    },

    username(state) {
      if (!state.user) {
        return null;
      }
      return state.user.userName;
    },

    siteId(state) {
      if (!state.user) {
        return null;
      }
      return state.user.siteId;
    },

    /**
     * Check the competences of admins
     */
    isDataScientistAdmin(state) {
      return isAdmin(state.roles) && isDataScientist(state.competences);
    },
    isPublisherAdmin(state) {
      return isAdmin(state.roles) && isPublisher(state.competences);
    },
    isAccountExecutiveAdmin(state) {
      return isAdmin(state.roles) && isAccountExecutive(state.competences);
    },
    isAdminManagerAdmin(state) {
      return isAdmin(state.roles) && isAdminManager(state.competences);
    },
    isDataExportManagerAdmin(state) {
      return isAdmin(state.roles) && isDataExportManager(state.competences);
    },
    isOptionalFeatureManagerAdmin(state) {
      return (
        isAdmin(state.roles) && isOptionalFeatureManager(state.competences)
      );
    },
  },
};
