import { Model } from "@vuex-orm/core";
import { parseISO } from "date-fns";
import Profile from "@/models/Profile.model";
import { ProductKind } from "@/models/utils";

export default class MMGOWeeklyActiveTrainingTimeGoal extends Model {
  static entity = "mmgoWeeklyActiveTrainingTimeGoal";

  static fields() {
    return {
      id: this.attr(null),
      addedAtUtc: this.attr(null),
      validFromDate: this.attr(null),
      patientUsername: this.attr(null),
      therapistUsername: this.attr(null),
      goalInSeconds: this.attr(null),
      device: this.attr(ProductKind.MMGO),
    };
  }

  get AddedAtUtc() {
    return this.addedAtUtc ? new Date(this.addedAtUtc) : null;
  }

  get ValidFromDate() {
    return this.validFromDate ? parseISO(this.validFromDate) : null;
  }

  get TherapistDisplayName() {
    return Profile.query().find(this.therapistUsername)?.displayedName || "";
  }

  get GoalInMinutes() {
    return parseInt(this.goalInSeconds / 60);
  }

  /**
   * Get MMGO weekly ATT goal history for the current patient
   * @param {string} username Patient username
   * @param {AbortSignal} signal
   */
  static async getWeeklyActiveTrainingTimeHistoryAsync(
    username,
    signal = null
  ) {
    return await this.api().get(`/weekly-training-goal/${username}/history`, {
      dataKey: "result",
      signal,
    });
  }

  /**
   * Get a weekly ATT goal for the current patient
   * Does not save the response to the store
   * @param {string} username Patient username
   * @param {AbortSignal} signal
   */
  static async getNonPersistentWeeklyActiveTrainingTimeGoalAsync(
    username,
    signal = null
  ) {
    return await this.api().get(`weekly-training-goal/${username}`, {
      signal,
      save: false,
    });
  }

  /**
   * Post a new MMGO weekly activity time goal for the given patient
   * @param patientUsername
   * @param goalInSeconds
   * @param validFromDate
   * @param signal
   */
  static async postWeeklyActivityTimeGoalForPatient(
    patientUsername,
    goalInSeconds,
    validFromDate,
    signal = null
  ) {
    const payload = {
      patientUsername,
      goalInSeconds,
      validFromDate,
    };
    return await this.api().post(`/weekly-training-goal`, payload, {
      dataKey: "result",
      signal,
    });
  }
}
