<template>
  <transition appear name="slideFromBottom">
    <div
      v-if="isOpen"
      class="grid grid-cols-2 gap-4 lg:grid-cols-7 items-center cookie-bar-position z-cookie-bar w-9/10 p-8 shadow-lg bg-gray-100 rounded"
    >
      <div class="col-span-5">
        {{ $t("App.CookieBar.cookieMessage") }}
      </div>

      <button
        class="text-white bg-blue-500 hover:text-white hover:bg-blue-750"
        @click="accept"
      >
        {{ $t("App.CookieBar.acceptText") }}
      </button>
      <button
        class="text-white bg-blue-500 hover:text-white hover:bg-blue-750"
        @click="decline"
      >
        {{ $t("App.CookieBar.declineText") }}
      </button>
    </div>
  </transition>
</template>

<script>
import {
  acceptCookies,
  declineCookies,
  areCookiesAccepted,
  isCookieBarHidden,
} from "@/utils/cookies";
import { getLocalStorageStayLoggedIn } from "@/store/localStorage";
import { mapActions, mapState } from "vuex";

export default {
  mounted() {
    // Restore the previous hide cookie bar value when first loading the page
    if (isCookieBarHidden()) {
      this.setHideCookieBar(true);
    }
    this.isOpen = !this.hideCookieBar && !areCookiesAccepted();
    this.refreshStoreCookiesAcceptedState();
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState("Auth", ["hideCookieBar"]),
  },
  methods: {
    ...mapActions("Auth", [
      "setCookiesAccepted",
      "setStayLoggedIn",
      "setHideCookieBar",
    ]),
    refreshStoreCookiesAcceptedState() {
      const cookiesAccepted = areCookiesAccepted();
      // Refresh cookies acceptance state
      this.setCookiesAccepted(cookiesAccepted);
    },
    accept() {
      acceptCookies();
      // Broadcast the value through the store
      this.setCookiesAccepted(true);
      this.isOpen = false;
    },
    decline() {
      declineCookies();
      // Broadcast the value through the store
      this.setCookiesAccepted(false);
      this.isOpen = false;
    },
  },
  watch: {
    hideCookieBar() {
      this.isOpen = !this.hideCookieBar && !areCookiesAccepted();
    },
  },
};
</script>

<style scoped lang="postcss">
.cookie-bar-position {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
}

.slideFromBottom-enter {
  transform: translate(-50%, 100%);
}

.slideFromBottom-leave-to {
  transform: translate(-50%, 100%);
}

.slideFromBottom-enter-active {
  transition: transform 0.2s ease-out;
}
.slideFromBottom-leave-active {
  transition: transform 0.2s ease-in;
}
</style>
