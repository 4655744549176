<template>
  <portal to="modal" v-if="showModal">
    <transition name="modal-fade">
      <div
        v-if="showModal"
        class="modal flex flex-col fixed inset-0 items-center sm:justify-center"
        :class="backdropClasses"
        v-on="closeOutOfFocus ? { click: onClose } : {}"
      >
        <div
          class="flex flex-col modal bg-white rounded opacity-100 justify-between border-t-6 border-solid border-blue-500 min-w-160"
          @click.stop
          :class="triggerClassModal"
        >
          <div
            :class="triggerClassHeader"
            class="flex justify-between items-start py-4 px-8"
            v-if="showHeader"
          >
            <slot name="header">&nbsp;</slot>
            <div v-if="closeButton" class="-me-4">
              <base-button @click="onClose" class="btn-icon ms-auto">
                <svg-icon name="close" class="icon-md text-blue-800" />
              </base-button>
            </div>
          </div>
          <div
            :class="triggerClassContent"
            class="scrollbar modal-content flex-grow"
          >
            <slot />
          </div>
          <div :class="triggerClassFooter" class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    triggerClassModal: {
      type: String,
    },
    triggerClassHeader: {
      type: String,
    },
    triggerClassContent: {
      type: String,
    },
    triggerClassFooter: {
      type: String,
    },
    backdropClass: {
      type: String,
      default: "",
    },
    blurModalLayout: {
      type: Boolean,
      default: false,
    },
    closeOutOfFocus: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      classes: {
        type: Object,
      },
    };
  },
  computed: {
    backdropClasses() {
      return [
        this.blurModalLayout ? "modal-backdrop-blurred" : "modal-backdrop",
        ...this.backdropClass.split(" ").filter((value) => !!value),
      ];
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    handleKeyEvent(event) {
      if (event.code == "Escape" && this.showModal) {
        this.onClose();
      }
    },
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyEvent);
  },
};
</script>

<style lang="postcss" scoped>
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

::v-deep .modal {
  @apply flex flex-col;

  width: 100%;

  & .is-clean {
    @apply p-0;
  }
}

@media (width > 640px) {
  ::v-deep .modal {
    min-width: 500px;
  }

  ::v-deep .modal-sm {
    max-width: 550px;
  }
}

@media (width >= 768px) {
  ::v-deep .modal {
    max-width: 600px;
  }

  ::v-deep .modal-md {
    max-width: 800px;
  }
}

@media (width >= 1024px) {
  ::v-deep .modal-lg {
    max-width: 960px;
  }
}

@media (width >= 1280px) {
  ::v-deep .modal-xl {
    max-width: 1200px;
  }
}

@media (height < 600px) {
  ::v-deep .modal {
    height: 100%;
  }

  ::v-deep .modal-height-lg {
    height: 100%;
  }

  ::v-deep .modal-height-xl {
    height: 100%;
  }
}

@media (height >= 600px) {
  ::v-deep .modal {
    max-height: 80vh;
  }

  ::v-deep .modal-height-lg {
    height: 60vh;
  }

  ::v-deep .modal-height-xl {
    height: 80vh;
  }
}

::v-deep .modal-fullscreen {
  width: 98%;
  min-width: 98%;
  height: 98%;
  min-height: 98%;
}

.modal-content {
  @apply px-8 pt-2;
  @apply overflow-auto;
}

.modal-footer {
  @apply px-8;
}
</style>
