import Store from "@/store/";
import { getLanguageCodeFrom } from "@/utils/locales";

export const ProductKind = Object.freeze({
  MMGO: "mmGo",
  MMGOMobile: "mmGoMobile",
  MMPRO: "mmPRO",
  MindPod: "mindPod",
  ToapRun: "toapRun",
});

export const userRootLocale = () => {
  return getLanguageCodeFrom(Store.getters["Language/language"]);
};

/**
 * Maps gesture provider messy ids into usable movement ids
 * This should ideally one day be streamlined in the activity definition.
 * @param gestureProviderId
 * @return {string|*}
 */
export default function gestureProviderIdToMovementId(gestureProviderId) {
  if (!gestureProviderId) {
    return "";
  }

  let movementId = gestureProviderId.replace("GestureProvider", "");

  // Map the "exceptions"
  const staticMap = {
    HandsObjectsGrasping: "HorizontalArmReaching",
    FakeSteeringWheel: "SteeringWheel",
    HandsCoronal: "VerticalHandsReaching",
    ShouldersLateralAbdAdd: "ShoulderAbductionAdduction",
    ShouldersHorizontalAbdAdd: "HorizontalShoulderAbductionAdduction",
    ShouldersFlexionExtension: "ShoulderFlexionExtension",
    HandOpenCloseMcpa: "HandGraspRelease",
    HandPronationSupination: "ForearmPronationSupination",
    IndexThumbPinch: "FingersPinch",
    BodyWeightTransfer: "LateralBodyWeightTransfer",
    FootStepLateral: "LateralStep",
    FootStepForwardBackward: "ForwardBackwardSteps",
    TrunkLateralFlexionBodyCentric: "TrunkLateralFlexion",
    // Old gesture providers that must be ignored
    // Reference: https://intranet.mindmaze.ch/confluence/pages/viewpage.action?spaceKey=DHT&title=Normalized+distance+traveled
    ArmsUpDown: "",
    HandsUp: "",
    ShouldersToHandsLateral: "",
    FingersFlexionExtension: "",
    HandGrabbing: "",
    TrunkForwardFlexion: "", // Not to confuse with TrunkBackwardForwardFlexion
    KneesFlexionExtension: "",
  };
  if (staticMap[movementId] !== undefined) {
    movementId = staticMap[movementId];
  }

  return movementId;
}
